<template>
  <div class="row">
    <input-text v-model="filtro.nome" class="col-12 col-md-6" :label="$t('modulos.tipo_instrumento.formulario.nome')"
      :placeholder="$t('modulos.tipo_instrumento.formulario.nome')" em-filtro />

    <input-select v-model="filtro.tipoSetor" class="col-12 col-md-6"
      :label="$t('modulos.tipo_instrumento.formulario.tipo_setor')" :placeholder="$t('geral.inputs.selecione')"
      :loading="loadingTipoSetor" :options="opcoes.tipoSetor" em-filtro clearable retornar-objeto />

    <input-text v-model="filtro.sigla" class="col-12 col-md-6" :label="$t('modulos.tipo_instrumento.formulario.sigla')"
      :placeholder="$t('modulos.tipo_instrumento.formulario.sigla')" em-filtro />

    <input-select v-model="filtro.flagExecutaManutencao" :options="opcoes.booleano" class="col-12 col-md-6"
      :label="$t('modulos.tipo_instrumento.formulario.executa_manutencao')" :placeholder="'Selecione'" em-filtro
      clearable retornar-objeto />

    <input-select v-model="filtro.flagExecutaCalibracao" :options="opcoes.booleano" class="col-12 col-md-6"
      :label="$t('modulos.tipo_instrumento.formulario.executa_calibracao')" :placeholder="'Selecione'" em-filtro
      clearable retornar-objeto />

  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import TipoSetoresService from '@common/services/cadastros/TipoSetoresService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { orderBy } from 'lodash';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        booleano: helpers.BoleanoEnum,
        tipoSetor: []
      },
      loadingTipoSetor: false
    };
  },
  mounted() {
    this.loadingTipoSetor = true;
    let parametros = {
      amountPerPage: 9999,
    };
    TipoSetoresService.listar(parametros).then((res) => {
      this.opcoes.tipoSetor = new DropdownModel(res.data.items, 'descricao');
      this.opcoes.tipoSetor = orderBy(this.opcoes.tipoSetor, ['text'], ['asc'])
      this.loadingTipoSetor = false;
    });
  }
};
// enumOrcamentoIcons.map((situacao) => { return { value: situacao.value, text: situacao.message };})
</script>
